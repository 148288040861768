<template>
  <div class="product-details--status flex__between">
    <p class="text__powder mr-10">{{ heading }}</p>
    <!-- <font-awesome-icon :icon="icon" size="lg" class="mr-10" /> -->
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: "live"
    }
  },
  computed: {
    // icon() {
    //   if (this.status === "test") {
    //     return "tools";
    //   }
    //   if (this.status === "coming-soon") {
    //     return "clock";
    //   }
    //   return "clipboard-check";
    // },
    heading() {
      if (this.status === "test") {
        return "Working on it!";
      }
      if (this.status === "coming-soon") {
        return "Coming Soon!";
      }
      return "Ready to Order!";
    }
    // statusClass() {
    //   if (this.status === "test") {
    //     return "test";
    //   }
    //   if (this.status === "coming-soon") {
    //     return "soon";
    //   }
    //   return "ready";
    // }
  }
};
</script>

<style scoped lang="scss">
.product-details--status {
  padding: 0px 10px;
  color: $powder;
  clip-path: polygon(0% 0%, 100% 0, 91% 100%, 0% 100%);
  background: linear-gradient(135deg, $dark-blue, $peacock);
  width: 80%;
  max-width: 300px;
  margin-bottom: 5px;
}
</style>