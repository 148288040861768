<template>
  <section class="product-meta tile-mini">
    <div class="flex__between">
      <h2>Details</h2>
      <button class="btn btn__sm btn__red" @click="toggleEditing">{{ editing ? "Cancel" : "Edit" }}</button>
    </div>
    <div class="product-meta__grid p-tb-sm">
      <label :class="[errors.name ? 'form--error' : null]" class="flex__col">
        Product Name*
        <br />
        <span v-if="!editing" class="pre-wrap">{{ product.name }}</span>
        <textarea v-else type="text" v-model="form.name" rows="3" />
      </label>
      <form-error v-if="editing" :message="errors.name" />
      <label class="flex__between">
        Status
        <span v-if="!editing">{{ product.status }}</span>
        <select v-else v-model="form.status">
          <option value="test">Test</option>
          <option value="live">Live</option>
          <option value="coming-soon">Coming Soon</option>
        </select>
      </label>
      <label class="flex__between">
        Is Featured
        <span v-if="!editing">{{ product.isFeatured ? "Yes" : "No" }}</span>
        <select v-else v-model="form.isFeatured">
          <option :value="false">No</option>
          <option :value="true">Yes</option>
        </select>
      </label>
      <label class="flex__between">
        Is Inventoried
        <span v-if="!editing">{{ product.isInventoried ? "Yes" : "No" }}</span>
        <select v-else v-model="form.isInventoried">
          <option :value="false">No</option>
          <option :value="true">Yes</option>
        </select>
      </label>
    </div>
    <div v-if="editing" class="flex__between">
      <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
      <button class="btn btn__sm btn__green" @click="submit">Done</button>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { createError } from "../../../libs/flash-messages";
export default {
  props: ["canEdit", "product"],
  data() {
    return {
      editing: false,
      isValidated: true,
      form: {
        _id: this.product ? this.product._id : null,
        name: this.product ? this.product.name : "",
        status: this.product ? this.product.status : "",
        isFeatured: this.product ? this.product.isFeatured : false,
        isInventoried: this.product ? this.product.isInventoried : false
      },
      errors: {
        name: null
      }
    };
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateProduct"]),
    async submit() {
      if (!this.canEdit)
        return createError(
          "Access denied",
          "You do not have permission to perform this action"
        );
      this.validateForm();
      if (this.isValidated) {
        this.startSetIsSubmitting({ bool: true, heading: "Updating" });
        try {
          await this.startUpdateProduct(this.form);
          this.startSetIsSubmitting({ bool: false });
          this.toggleEditing();
        } catch (err) {
          this.startSetIsSubmitting({ bool: false });
        }
      }
    },
    validateForm() {
      this.isValidated = true;
      this.errors.name = this.form.name === "" ? "Please enter a name" : null;
      for (let key in this.errors) {
        if (this.errors[key] === null) {
          this.isValidated = this.isValidated && true;
        } else {
          this.isValidated = this.isValidated && false;
        }
      }
    },
    toggleEditing() {
      if (this.canEdit) {
        this.editing = !this.editing;
      }
      return;
    }
  }
};
</script>

<style scoped lang="scss">
.product-meta__grid {
  @include grid($cols: 1fr, $row-gap: 15px);
  .flex__between {
    @include grid($cols: 1fr 1.5fr, $col-gap: 10px, $align: center);
  }
  label {
    font-weight: bold;
    span {
      font-weight: normal;
      text-align: right;
    }
  }
}
@media (min-width: $xs) {
  .product-meta__grid label {
    grid-template-columns: 1.25fr 2fr;
    span {
      text-align: right;
    }
  }
}
@media (min-width: $md) {
  .product-meta {
    margin-bottom: 0;
  }
}
</style>