<template>
  <section class="product-admin tile-mini">
    <div class="flex__between">
      <h2>Admin</h2>
      <button class="btn btn__sm btn__red" @click="toggleEditing">{{ editing ? "Cancel" : "Edit" }}</button>
    </div>
    <p>Both item cost and invoice cost below will be copied to an order item when one is created. It can be changed from the order item screen.</p>
    <label :class="[ errors.itemCost ? 'form--error' : null ]">
      Item Cost
      <span v-if="!editing">${{ product.itemCost.toFixed(2) }}</span>
      <input v-else type="number" v-model="form.itemCost" min="0" max="1000" placeholder="0.00" />
    </label>
    <form-error v-if="editing" :message="errors.itemCost" />
    <label :class="[ errors.invoiceCost ? 'form--error' : null ]">
      Invoice Cost
      <span v-if="!editing">${{ product.invoiceCost.toFixed(2) }}</span>
      <input v-else type="number" v-model="form.invoiceCost" min="0" max="1000" placeholder="0.00" />
    </label>
    <form-error v-if="editing" :message="errors.invoiceCost" />
    <label :class="[ errors.approxValue ? 'form--error' : null ]">
      Approximate Cost
      <span
        v-if="!editing"
      >${{ product.approxValue ? product.approxValue.toFixed(2) : "0.00" }}</span>
      <input v-else type="number" v-model="form.approxValue" min="0" max="1000" placeholder="0.00" />
    </label>
    <p>--for testing respa eligibility</p>
    <form-error v-if="editing" :message="errors.approxValue" />
    <div v-if="editing" class="flex__between">
      <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
      <button class="btn btn__sm btn__green" @click="submit">Done</button>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { createError } from "../../../libs/flash-messages";
export default {
  props: ["canEdit", "product"],
  data() {
    return {
      editing: false,
      isValdated: true,
      form: {
        _id: this.product ? this.product._id : null,
        itemCost: this.product ? this.product.itemCost.toFixed(2) : 0,
        invoiceCost: this.product ? this.product.invoiceCost.toFixed(2) : 0,
        approxValue:
          this.product && this.product.approxValue
            ? this.product.approxValue.toFixed(2)
            : 0
      },
      errors: {
        itemCost: null,
        invoiceCost: null,
        approxValue: null
      }
    };
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateProduct"]),
    async submit() {
      if (!this.canEdit)
        return createError(
          "Access denied",
          "You are not permitted to perform this action"
        );
      this.validateForm();
      if (this.isValidated) {
        this.startSetIsSubmitting({ bool: true, heading: "Updating" });
        try {
          await this.startUpdateProduct(this.form);
          this.startSetIsSubmitting({ bool: false });
          this.toggleEditing();
        } catch (err) {
          this.startSetIsSubmitting({ bool: false });
        }
      }
    },
    validateForm() {
      this.isValidated = true;
      this.errors.itemCost =
        isNaN(parseFloat(this.form.itemCost)) ||
        parseFloat(this.form.itemCost) < 0
          ? "Cost must be 0 or more"
          : null;
      this.errors.invoiceCost =
        isNaN(parseFloat(this.form.invoiceCost)) ||
        parseFloat(this.form.invoiceCost) < 0
          ? "Please enter a number that is 0 or more"
          : null;
      this.errors.approxValue =
        isNaN(parseFloat(this.form.approxValue)) ||
        parseFloat(this.form.approxValue) < 0
          ? "Please enter a number that is 0 or more"
          : null;
      for (let key in this.errors) {
        if (this.errors[key] === null) {
          this.isValidated = this.isValidated && true;
        } else {
          this.isValidated = this.isValidated && false;
        }
      }
    },
    toggleEditing() {
      if (this.canEdit) {
        this.editing = !this.editing;
      }
      return;
    }
  }
};
</script>

<style scoped lang="scss">
.product-admin {
  @include grid($cols: 1fr, $row-gap: 15px);
  label {
    @include grid($cols: 1fr, $row-gap: 3px, $col-gap: 10px, $align: center);
    font-weight: bold;
    span {
      font-weight: normal;
      text-align: right;
    }
  }
}
@media (min-width: $xs) {
  .product-admin label {
    grid-template-columns: 1fr 2fr;
  }
}
@media (min-width: $md) {
  .product-admin label {
    grid-template-columns: 1fr;
  }
}
@media (min-width: $lg) {
  .product-admin label {
    grid-template-columns: 1fr 2fr;
  }
}
</style>