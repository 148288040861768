<template>
  <section>
    <p class="text__bold text__md mb-15 text__center">Product Info</p>
    <table>
      <tbody>
        <tr>
          <td>Unit Cost</td>
          <td>${{ product.loCost.toFixed(2) }}</td>
        </tr>
        <tr>
          <td>Dimensions</td>
          <td>{{ product.info.dimensions }}</td>
        </tr>
        <tr>
          <td>Weight</td>
          <td>{{ product.info.weight }}</td>
        </tr>
        <tr>
          <td>Customizations</td>
          <td>
            <ul>
              <li v-if="product.customizations.length <= 0">None</li>
              <li v-else v-for="cust in product.customizations" :key="cust._id">{{ cust.name }}</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Categories</td>
          <td>
            <ul class="list__normal">
              <li v-if="categoryList.length <= 0">No Categories</li>
              <li v-else v-for="category in categoryList" :key="category._id">{{ category.name }}</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Features</td>
          <td v-html="product.info.features"></td>
        </tr>
      </tbody>
      <tbody></tbody>
    </table>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["product"],
  computed: {
    ...mapGetters(["getCategories"]),
    categoryList() {
      return this.product.categories.map(id => {
        return this.getCategories.filter(category => {
          return category._id === id;
        })[0];
      });
    }
  }
};
</script>

<style lang="scss">
table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
}
tbody tr {
  border: 2px solid darken($frost, 10%);
}
td:nth-child(1) {
  /* background-color: $frost; */
  width: 30%;
  padding: 10px;
  font-weight: bold;
}
td:nth-child(2) {
  padding: 10px;
}
tr:nth-child(odd) {
  td:nth-child(1) {
    background-color: lighten($frost, 10%);
  }
  td:nth-child(2) {
    background-color: lighten($frost, 20%);
  }
}
tr:nth-child(even) {
  td:nth-child(1) {
    background-color: $frost;
  }
  td:nth-child(2) {
    background-color: lighten($frost, 10%);
  }
}
td ul {
  list-style: disc;
  list-style-position: inside;
}
</style>