<template>
  <section v-if="getRelatedProducts" class="margin__md">
    <div class="tile-heading">
      <h2>Related Products</h2>
      <hr />
    </div>
    <VueSlickCarousel v-bind="settings">
      <!-- <ul class="related-list"> -->
      <Product
        v-for="product in getRelatedProducts"
        :key="product._id"
        :product="product"
        class="list__none"
      />
      <!-- </ul> -->
    </VueSlickCarousel>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import VueSlickCarousel from "vue-slick-carousel";
import Product from "../Products/Product";
export default {
  components: {
    VueSlickCarousel,
    Product
  },
  data() {
    return {
      settings: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
        initialSlide: 0,
        focusOnSelect: false,
        responsive: [
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["getRelatedProducts"])
  }
};
</script>

<style scoped lang="scss">
.related-list {
  @include grid($cols: 1fr, $row-gap: 25px, $col-gap: 25px);
}
.list__none {
  list-style: none;
}
@media (min-width: $sm) {
  .related-list {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $lg) {
  .related-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>