<template>
  <section class="product-details margin__md pos__rel">
    <div v-if="isAdmin" class="flex__right">
      <button
        class="btn btn__sm btn__red z-special"
        @click="toggleEditing"
      >{{ editing ? "Cancel" : "Edit" }}</button>
    </div>
    <Status :status="product.status" />
    <div class="product-details__grid">
      <label class="flex__between" :class="[errors.price ? 'form--error' : null]">
        <span v-if="isAdmin" class="text__bold mr-15 no-wrap">LO Cost*</span>
        <span v-else class="text__bold mr-15">Price</span>
        <span
          v-if="!editing"
          class="text__md text__bold"
        >${{ parseFloat(product.loCost).toFixed(2) }}</span>
        <input v-else type="number" v-model="form.loCost" placeholder="0.00" />
      </label>
      <form-error v-if="editing" :message="errors.price" />
      <label :class="[errors.description ? 'form--error' : null]">
        Description{{ isAdmin ? "*" : "" }}
        <p v-if="!editing" class="product-details--description pre-wrap">{{ product.description }}</p>
        <textarea v-else v-model="form.description" rows="6" />
      </label>
      <form-error v-if="editing" :message="errors.description" />
      <!-- <label class="flex__between">
        Respa Status
        <respa v-if="!editing" :status="product.respaStatus" />
        <select v-else v-model="form.respaStatus">
          <option value="employee only">Employee Only</option>
          <option value="any">Any Order</option>
          <option value="co rec reqd">Co-Rec Reqd</option>
        </select>
      </label> -->
      <router-link
        v-if="!isAdmin && canEdit"
        :to="`/admin/products/${product._id}`"
        class="btn btn__sm btn__green"
      >
        Admin Product
        <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
      </router-link>
    </div>
    <div v-if="isAdmin && editing" class="flex__between">
      <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
      <button class="btn btn__sm btn__green" @click="submit">Done</button>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { createError } from "../../../libs/flash-messages";
import Status from "./Status";
export default {
  props: ["canEdit", "product"],
  components: {
    Status
  },
  data() {
    return {
      editing: false,
      isValidated: true,
      form: {
        _id: this.product ? this.product._id : null,
        loCost: this.product ? this.product.loCost.toFixed(2) : 0,
        description: this.product ? this.product.description : "",
        respaStatus: this.product ? this.product.respaStatus : "employee only"
      },
      errors: {
        loCost: null,
        description: null
      }
    };
  },
  computed: {
    route() {
      return this.$route.fullPath;
    },
    isAdmin() {
      return this.route.includes("/admin");
    }
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateProduct"]),
    async submit() {
      if (!this.canEdit)
        return createError(
          "Access denied",
          "You are not permitted to perform this action"
        );
      this.validateForm();
      if (this.isValidated) {
        this.startSetIsSubmitting({ bool: true, heading: "Updating" });
        try {
          await this.startUpdateProduct(this.form);
          this.startSetIsSubmitting({ bool: false });
          this.toggleEditing();
        } catch (err) {
          this.startSetIsSubmitting({ bool: false });
        }
      }
    },
    validateForm() {
      this.isValidated = true;
      this.errors.loCost =
        isNaN(parseFloat(this.form.loCost)) || parseFloat(this.form.loCost) <= 0
          ? "Please enter a number more than 0"
          : null;
      this.errors.description =
        this.form.description.length <= 0
          ? "Please enter something for the description"
          : null;
      for (let key in this.errors) {
        if (this.errors[key] === null) {
          this.isValidated = this.isValidated && true;
        } else {
          this.isValidated = this.isValidated && false;
        }
      }
    },
    toggleEditing() {
      if (this.canEdit) {
        this.editing = !this.editing;
      }
      return;
    }
  }
};
</script>

<style scoped lang="scss">
.z-special {
  z-index: 2;
}
.product-details__grid {
  @include grid($cols: 1fr, $row-gap: 15px);
  padding-top: 20px;
  padding-bottom: 10px;
  label {
    font-weight: bold;
    p {
      font-weight: normal;
    }
  }
}
textarea {
  height: inherit;
}
</style>