<template>
  <section class="product-admin tile">
    <div class="flex__between">
      <h2>Order Item Details</h2>
      <button
        v-if="canEdit"
        class="btn btn__sm btn__red"
        @click="toggleEditing"
      >{{ editing ? "Cancel" : "Edit" }}</button>
    </div>
    <label>
      Status
      <span v-if="!editing">{{ orderItem.status }}</span>
      <select v-else v-model="form.status">
        <option value="Pending" :disabled="form.status === 'Completed'">Pending</option>
        <option value="Completed" :disabled="form.status === 'Completed'">Completed</option>
      </select>
    </label>
    <label v-if="canEdit">
      Item Cost
      <span v-if="!editing">${{ orderItem.itemCost.toFixed(2) }}</span>
      <input v-else type="number" v-model="form.itemCost" min="0" max="1000" placeholder="0.00" />
    </label>
    <form-error v-if="editing" :message="errors.itemCost" />
    <label v-if="canEdit" :class="[errors.invoiceCost ? 'form--error' : null]">
      Invoice Cost
      <span v-if="!editing">${{ orderItem.invoiceCost.toFixed(2) }}</span>
      <input v-else type="number" v-model="form.invoiceCost" min="0" max="1000" placeholder="0.00" />
    </label>
    <form-error v-if="editing" :message="errors.invoiceCost" />
    <label v-if="editing">
      Upload Receipt
      <span class="text__sm text__left">
        <a
          :href="`https://techsupportflatbranch.sharepoint.com/teams/WOWDesk/Shared%20Documents/Forms/AllItems.aspx?viewid=a678bc10%2D6d70%2D4237%2Dbd50%2D3836adee4639&id=%2Fteams%2FWOWDesk%2FShared%20Documents%2Fwow%2Dinventory`"
          target="_blank"
        >Sharepoint / WOW Desk / wow-inventory</a>
      </span>
    </label>
    <label v-if="canEdit">
      Receipt Link
      <span v-if="!editing && canEdit">
        <a v-if="orderItem.receipt" :href="orderItem.receipt" target="_blank">View Receipt</a>
        <span v-else>none provided</span>
      </span>
      <input
        v-if="editing && canEdit"
        type="text"
        v-model="form.receipt"
        placeholder="sharepoint link"
      />
    </label>
    <label>
      Tracking Info
      <span
        v-if="!editing"
      >{{ orderItem.trackingNum ? orderItem.trackingNum : "none" }}</span>
      <input v-else type="text" v-model="form.trackingNum" />
    </label>
    <label v-if="canEdit">
      Current Inventory
      <span
        v-if="!editing"
      >{{ product.isInventoried ? product.inventory : "untracked" }}</span>
      <router-link
        v-if="editing && product.isInventoried"
        :to="`/admin/inventory-products?product=${product._id}`"
        class="btn btn__frost"
      >Add Inventory</router-link>
      <span v-if="editing && !product.isInventoried">untracked</span>
    </label>
    <div v-if="editing && canEdit" class="flex__between">
      <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
      <button class="btn btn__sm btn__green" @click="submit">Done</button>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
export default {
  props: ["order", "canEdit", "orderItem", "product"],
  data() {
    return {
      editing: false,
      isValdated: true,
      form: {
        order: this.order ? this.order._id : null,
        _id: this.orderItem ? this.orderItem._id : null,
        itemCost: this.orderItem ? this.orderItem.itemCost : null,
        invoiceCost: this.orderItem ? this.orderItem.invoiceCost : null,
        receipt: this.orderItem ? this.orderItem.receipt : null,
        status: this.orderItem ? this.orderItem.status : null,
        trackingNum: this.orderItem ? this.orderItem.trackingNum : null
      },
      errors: {
        itemCost: null,
        invoiceCost: null
      },
      completeForm: {
        requesterName: this.order ? this.order.requesterName : null,
        requesterEmail: this.order ? this.order.requesterEmail : null,
        billingName: this.order ? this.order.billingName : null,
        billingEmail: this.order ? this.order.billingEmail : null,
        order: this.order ? this.order._id : null,
        hfTicketId: this.order ? this.order.hfTicketId : null,
        recipientName: this.order ? this.order.recipientName : null,
        coRecipientName: this.order ? this.order.coRecipientName : null,
        shippingName: this.order ? this.order.shippingName : null,
        recipientType: this.order ? this.order.recipientType : null,
        loanNumber: this.order ? this.order.loanNumber : null,
        requestDate: this.order
          ? moment(this.order.createdAt).format("YYYY-MM-DD hh:mm:ss")
          : null,
        orderItem: this.orderItem ? this.orderItem._id : null,
        product: this.orderItem ? this.orderItem.product : null,
        productName: this.orderItem ? this.orderItem.productName : null,
        quantity: this.orderItem ? this.orderItem.quantity : null,
        loCost: this.orderItem ? this.orderItem.loCost : null,
        itemCost: this.orderItem ? this.orderItem.itemCost : null,
        awardAmountUsed: this.order ? this.order.awardAmountUsed : null,
        receipt: this.orderItem ? this.orderItem.receipt : null,
        billingMethod: this.order ? this.order.billingMethod : null,
        milestone: this.order ? this.order.milestone : null,
        isInventoried: this.product ? this.product.isInventoried : false,
        comments: this.order ? this.order.comments : null,
        bulkOrder: this.order && this.bulkOrder ? true : false
      }
    };
  },
  computed: {
    isAdmin() {
      return this.$route.fullPath.includes("/admin");
    }
  },
  methods: {
    ...mapActions([
      "startSetIsSubmitting",
      "startUpdateOrderItem",
      "startCompleteOrderItem"
    ]),
    async submit() {
      this.validateForm();
      if (!this.isValidated) return;
      this.startSetIsSubmitting({ bool: true, heading: "Updating" });
      try {
        await this.startUpdateOrderItem(this.form);
        if (this.form.status === "Completed") {
          await this.startCompleteOrderItem(this.completeForm);
        }
        this.startSetIsSubmitting({ bool: false });
        this.toggleEditing();
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    validateForm() {
      this.isValidated = true;
      this.errors.itemCost =
        isNaN(parseFloat(this.form.itemCost)) ||
        parseFloat(this.form.itemCost) < 0
          ? "Cost must be 0 or more"
          : null;
      this.errors.invoiceCost =
        isNaN(parseFloat(this.form.invoiceCost)) ||
        parseFloat(this.form.invoiceCost) < 0
          ? "Please enter a number that is 0 or more"
          : null;
      for (let key in this.errors) {
        if (this.errors[key] === null) {
          this.isValidated = this.isValidated && true;
        } else {
          this.isValidated = this.isValidated && false;
        }
      }
    },
    toggleEditing() {
      if (this.canEdit) {
        this.editing = !this.editing;
      }
      return;
    }
  }
};
</script>

<style scoped lang="scss">
.product-admin {
  @include grid($cols: 1fr, $row-gap: 15px);
  label {
    @include grid($cols: 1fr, $row-gap: 3px, $col-gap: 10px, $align: center);
    font-weight: bold;
    span {
      font-weight: normal;
      text-align: right;
    }
    .text__left {
      text-align: left;
    }
  }
}
@media (min-width: $xs) {
  .product-admin label {
    grid-template-columns: 1fr 2fr;
  }
}
@media (min-width: $md) {
  .product-admin label {
    grid-template-columns: 1fr;
  }
}
@media (min-width: $lg) {
  .product-admin label {
    grid-template-columns: 1fr 2fr;
  }
}
</style>