<template>
  <div>
    <p class="text__lg text__center text__bold text__white">Delete Product</p>
    <p
      class="text__white text__center p-tb-sm"
    >Deleting this product cannot be undone, it will no longer be available to view on this site in any way.</p>
    <p class="text__md text__center text__bold text__white">Proceed?</p>
    <div class="flex__between">
      <button class="btn btn__sm btn__frost" @click="emitToggleDeleting">No, Go Back</button>
      <button class="btn btn__sm btn__green" @click="deleteProduct">Yes, Delete</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { createError } from "../../../libs/flash-messages";
export default {
  props: ["canEdit", "product"],
  data() {
    return {
      form: {
        _id: this.product ? this.product._id : null,
        name: this.product ? this.product.name : null
      }
    };
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startDeleteProduct"]),
    emitToggleDeleting() {
      this.$emit("toggleDeleting", false);
    },
    async deleteProduct() {
      if (!this.canEdit)
        return createError(
          "Access denied",
          "You are not permitted to perfor this action"
        );
      this.startSetIsSubmitting({ bool: true, heading: "Deleting" });
      try {
        await this.startDeleteProduct(this.form);
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    }
  }
};
</script>

<style scoped lang="scss">
</style>