<template>
  <section>
    <p class="text__bold text__md mb-15 text__center">Pair it with...</p>
    <div class="pair-show tile">
      <div class="flex__center">
        <img :src="paired.images[0]" :alt="`Image for ${paired.name}`" width="100%" height="100%" />
      </div>
      <p class="text__bold text__md">{{ paired.name }}</p>
      <p>
        <span class="text__bold mr-10">Cost</span>
        <span>${{ paired.loCost.toFixed(2) }}</span>
      </p>
      <p>
        <span class="text__bold mr-10">Description</span>
        <br />
        <span>{{ paired.description.length > 150 ? `${paired.description.slice(0, 150)}...` : paired.description }}</span>
      </p>
      <div class="flex__right">
        <router-link :to="`/products/${paired._id}`" class="breadcrumb">
          View
          <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["product"],
  computed: {
    ...mapGetters(["getLiveProducts"]),
    paired() {
      if (this.product.paired) {
        const found = this.getLiveProducts.filter(
          item => item._id === this.product.paired
        );
        if (found.length > 0) {
          return found[0];
        }
      }
      const similar = this.getLiveProducts.filter(
        item =>
          item.isInventoried === this.product.isInventoried &&
          item._id !== this.product._id
      );
      const rand = Math.floor(Math.random() * similar.length);
      return similar[rand];
    }
  }
};
</script>

<style scoped lang="scss">
.pair-show {
  @include grid($cols: 1fr, $row-gap: 8px);
  img {
    width: 200px;
    height: 200px;
  }
}
</style>