<template>
  <section class="product" :key="getActiveProduct._id">
    <Header
      @toggleDeleting="toggleDeleting"
      :canEdit="canEdit"
      :product="getActiveProduct"
      :order="getActiveProduct"
    />
    <div class="container">
      <h1 class="text__center padding__md pre-wrap">{{ getActiveProduct.name }}</h1>
      <div v-if="isAdmin" class="details__grid">
        <Meta :canEdit="canEdit" :product="getActiveProduct" />
        <Attr :categories="getCategories" :canEdit="canEdit" :product="getActiveProduct" />
      </div>
      <div class="product__grid">
        <Images :canEdit="canEdit" :product="getActiveProduct" />
        <Details :user="getUserDetails" :canEdit="canEdit" :product="getActiveProduct" />
      </div>
      <Customizations
        v-if="getActiveOrder && getActiveOrder.status === 'Started' || canEdit"
        :canEdit="canEdit"
        :product="getActiveProduct"
        :order="getActiveOrder"
        :orderItem="orderItem"
      />
      <RelatedSlider v-if="showRelated" />
      <Gallery v-if="showRelated" :product="getActiveProduct" />
      <div class="extra__grid margin__sm">
        <InfoShow v-if="!isAdmin" :product="getActiveProduct" />
        <PairShow v-if="showRelated" :product="getActiveProduct" />
      </div>
      <div class="admin__grid margin__md">
        <Notes v-if="showNotes" :canEdit="canEdit" :product="getActiveProduct" />
        <Admin v-if="showAdmin" :canEdit="canEdit" :product="getActiveProduct" />
        <OrderItem
          v-if="orderItem && showOrderItem"
          :canEdit="canEdit"
          :order="getActiveOrder"
          :orderItem="orderItem"
          :product="getActiveProduct"
        />
        <Info v-if="showAdmin" :canEdit="canEdit" :product="getActiveProduct" />
        <Paired v-if="showAdmin" :canEdit="canEdit" :product="getActiveProduct" />
      </div>
      <Related v-if="showAdmin" :canEdit="canEdit" :product="getActiveProduct" />
    </div>
    <delete v-if="deleting">
      <DeleteProduct
        @toggleDeleting="toggleDeleting"
        :canEdit="canEdit"
        :product="getActiveProduct"
      />
    </delete>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Header from "./Header";
import Meta from "./Metadata";
import Attr from "./Attributes";
import Images from "./Images/Index";
import Details from "./Details";
import Customizations from "./Customization/Index";
import Notes from "./Notes";
import RelatedSlider from "./RelatedSlider";
import Gallery from "./Gallery";
import Admin from "./Admin";
import OrderItem from "./OrderItem";
import Info from "./Info";
import InfoShow from "./InfoShow";
import PairShow from "./PairShow";
import Paired from "./Paired";
import Related from "./Related";
import DeleteProduct from "./DeleteProduct";
export default {
  components: {
    Header,
    Meta,
    Attr,
    Images,
    Details,
    Customizations,
    RelatedSlider,
    Gallery,
    Notes,
    Admin,
    OrderItem,
    Info,
    InfoShow,
    PairShow,
    Paired,
    Related,
    DeleteProduct
  },
  metaInfo: {
    title: "View Product | WOW"
  },
  data() {
    return {
      deleting: false,
      adminAccess: ["admin", "super-admin"]
    };
  },
  watch: {
    async $route(to, from, next) {
      try {
        await this.startSetActiveProduct(to.params.product);
      } catch (err) {
        next("/products");
      }
    }
  },
  computed: {
    ...mapGetters([
      "getCategories",
      "getUserDetails",
      "getActiveOrder",
      "getActiveProduct"
    ]),
    route() {
      return this.$route.fullPath;
    },
    isAdmin() {
      return this.route.includes("/admin");
    },
    showRelated() {
      return (
        !this.route.includes("/admin") && !this.route.includes("/past-order")
      );
    },
    showAdmin() {
      return this.route.includes("/admin") && this.canEdit;
    },
    showNotes() {
      return (
        (this.route.includes("/admin") || this.route.includes("/past-order")) &&
        this.canEdit
      );
    },
    showOrderItem() {
      return this.route.includes("/past-order");
    },
    orderItem() {
      if (this.$route.query.item) {
        return this.getActiveOrder.orderItems.filter(item => {
          return item._id === this.$route.query.item;
        })[0];
      }
      return null;
    },
    canEdit() {
      if (this.getUserDetails) {
        return this.adminAccess.includes(this.getUserDetails.permission);
      }
      return false;
    }
  },
  methods: {
    ...mapActions(["startSetActiveProduct"]),
    toggleDeleting() {
      this.deleting = !this.deleting;
    }
  }
};
</script>

<style scoped lang="scss">
.admin__grid {
  @include grid($cols: 1fr, $row-gap: 50px, $col-gap: 6%);
}
.details__grid {
  @include grid($cols: 1fr, $row-gap: 50px, $col-gap: 25px);
}
.extra__grid {
  @include grid($cols: 1fr, $row-gap: 50px, $col-gap: 25px);
}
@media (min-width: $md) {
  .details__grid {
    grid-template-columns: 1fr 1fr;
  }
  .admin__grid {
    grid-template-columns: 47% 47%;
  }
  .extra__grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $lg) {
  .product__grid {
    @include grid($cols: 6fr 3fr, $col-gap: 50px);
  }
}
</style>