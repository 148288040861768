<template>
  <section class="product-info tile-mini">
    <div class="flex__between">
      <h2>Product Info</h2>
      <button class="btn btn__sm btn__red" @click="toggleEditing">{{ editing ? "Cancel" : "Edit" }}</button>
    </div>
    <div class="product-info__grid p-tb-sm">
      <label class="flex__between">
        Weight
        <span v-if="!editing">{{ product.info.weight ? product.info.weight : "n/a" }}</span>
        <input v-else type="text" v-model="form.info.weight" />
      </label>
      <label class="flex__between">
        Dimensions
        <span
          v-if="!editing"
        >{{ product.info.dimensions ? product.info.dimensions : "n/a" }}</span>
        <input v-else type="text" v-model="form.info.dimensions" />
      </label>
      <label class="flex__between">
        Local
        <span v-if="!editing">{{ product.info.isLocal ? "Yes" : "No" }}</span>
        <select v-else v-model="form.info.isLocal">
          <option :value="false">No</option>
          <option :value="true">Yes</option>
        </select>
      </label>
      <label class="flex__col over__hidden">
        Features
        <div v-if="!editing && !product.info.features" class="padding__sm">
          <p class="text__bold text__center">No features for this product</p>
        </div>
        <div
          v-if="!editing && product.info.features"
          class="product-notes--display"
          v-html="product.info.features"
        ></div>
        <Editor v-if="editing" :api-key="apiKey" :init="config" v-model="form.info.features" />
      </label>
    </div>
    <div v-if="editing" class="flex__between">
      <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
      <button class="btn btn__sm btn__green" @click="submit">Done</button>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { createError } from "../../../libs/flash-messages";
import { tinyConfig } from "../../../plugins/tinymce";
export default {
  props: ["canEdit", "product"],
  data() {
    return {
      editing: false,
      form: {
        _id: this.product ? this.product._id : null,
        info: {
          weight: this.product ? this.product.info.weight : null,
          dimensions: this.product ? this.product.info.dimensions : null,
          isLocal: this.product ? this.product.info.isLocal : false,
          features: this.product ? this.product.info.features : ""
        }
      }
    };
  },
  computed: {
    apiKey() {
      return tinyConfig.apiKey;
    },
    config() {
      return tinyConfig.config;
    }
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateProduct"]),
    async submit() {
      if (!this.canEdit)
        return createError(
          "Access denied",
          "You do not have permission to perform this action"
        );
      this.startSetIsSubmitting({ bool: true, heading: "Updating" });
      try {
        await this.startUpdateProduct(this.form);
        this.startSetIsSubmitting({ bool: false });
        this.toggleEditing();
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    toggleEditing() {
      if (this.canEdit) {
        this.editing = !this.editing;
      }
      return;
    }
  }
};
</script>

<style lang="scss">
.product-info__grid {
  @include grid($cols: 1fr, $row-gap: 15px);
  .flex__between {
    @include grid($cols: 1fr 1.5fr, $col-gap: 10px, $align: center);
  }
  label {
    font-weight: bold;
    span {
      font-weight: normal;
      text-align: right;
    }
  }
}
.product-notes--display {
  font-weight: normal;
  ul {
    list-style: disc;
    list-style-position: inside;
  }
}
@media (min-width: $xs) {
  .product-meta__grid label {
    grid-template-columns: 1.25fr 2fr;
    span {
      text-align: right;
    }
  }
}
@media (min-width: $md) {
  .product-meta {
    margin-bottom: 0;
  }
}
</style>