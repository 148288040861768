<template>
  <section class="product-related tile-mini margin__md">
    <div class="flex__between">
      <h2>Related Products</h2>
    </div>
    <p>Selected products will be displayed at the bottom of this product listing</p>
    <div v-if="!selecting && !ordering" class="related-product">
      <div v-if="!related" class="default padding__md">
        <p class="text__bold text__center">No related products selected</p>
      </div>
      <simplebar v-else class="display--overflow" data-simplebar-auto-hide="false">
        <div class="display--list">
          <div v-for="product in product.related" :key="product._id" class="related-display">
            <img
              :src="getImage(product)"
              :alt="`Product image for ${getName(product)}`"
              width="100%"
              height="100%"
            />
            <p class="text__bold">{{ getName(product) }}</p>
          </div>
        </div>
      </simplebar>
    </div>
    <div v-if="selecting" class="related-product--search">
      <div class="search--bar mb-15">
        <input type="text" v-model="search" placeholder="search" />
        <div class="btn__search">
          <font-awesome-icon icon="search" size="lg" class="icon" />
        </div>
      </div>
      <simplebar class="products--overflow" data-simplebar-auto-hide="false">
        <ul class="products--list">
          <li v-for="product in filteredProducts" :key="product._id">
            <label :for="product._id">
              <input type="checkbox" :value="product._id" v-model="form.related" :id="product._id" />
              {{ product.name }}
            </label>
          </li>
        </ul>
      </simplebar>
    </div>
    <div v-if="ordering">
      <div v-if="!related" class="default padding__md">
        <p class="text__bold text__center">No related products selected</p>
      </div>
      <draggable v-else v-model="form.related">
        <transition-group>
          <div
            class="related-display reorder"
            v-for="product in form.related"
            :key="`${product}-reorder`"
          >
            <font-awesome-icon icon="grip-vertical" size="1x" />
            <img
              :src="getImage(product)"
              :alt="`Product image for ${getName(product)}`"
              width="100%"
              height="100%"
            />
            <p class="text__bold">{{ getName(product) }}</p>
          </div>
        </transition-group>
      </draggable>
    </div>
    <div v-if="selecting || ordering" class="flex__between">
      <button class="btn btn__sm btn__red" @click="toggle">Cancel</button>
      <button class="btn btn__sm btn__green" @click="submit">Done</button>
    </div>
    <div v-if="!selecting && !ordering" class="flex__between">
      <button class="btn btn__sm btn__green" @click="toggleOrdering">Set Order</button>
      <button class="btn btn__sm btn__frost" @click="toggleSelecting">Select</button>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import draggable from "vuedraggable";
import { createError } from "../../../libs/flash-messages";
export default {
  props: ["canEdit", "product"],
  components: {
    draggable
  },
  data() {
    return {
      editing: false,
      search: "",
      selecting: false,
      ordering: false,
      form: {
        _id: this.product ? this.product._id : null,
        related:
          this.product && this.product.related ? this.product.related : []
      }
    };
  },
  computed: {
    ...mapGetters(["getProducts"]),
    related() {
      if (!this.product.related || this.product.related.length <= 0)
        return null;
      const found = this.getProducts.filter(
        item =>
          this.product.related.includes(item._id) &&
          this.product._id !== item._id
      );
      if (found.length > 0) return found;
      return null;
    },
    filteredProducts() {
      if (!this.search)
        return this.getProducts.filter(
          product => this.product._id !== product._id
        );
      return this.getProducts.filter(
        product =>
          (product.name.toLowerCase().includes(this.search.toLowerCase()) ||
            product.tags.join(", ").includes(this.search.toLowerCase())) &&
          this.product._id !== product._id
      );
    }
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateProduct"]),
    getImage(id) {
      return this.related.filter(item => item._id === id)[0].images[0];
    },
    getName(id) {
      return this.related.filter(item => item._id === id)[0].name;
    },
    async submit() {
      if (!this.canEdit)
        return createError(
          "Access denied",
          "You do not have permission to perform this action"
        );
      this.startSetIsSubmitting({ bool: true, heading: "Updating" });
      try {
        await this.startUpdateProduct(this.form);
        this.startSetIsSubmitting({ bool: false });
        this.toggle();
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    toggle() {
      this.selecting = false;
      this.ordering = false;
    },
    toggleSelecting() {
      if (this.canEdit) {
        this.selecting = !this.selecting;
      }
      return;
    },
    toggleOrdering() {
      if (this.canEdit) {
        if (this.product.related && this.product.related.length > 0) {
          return (this.ordering = !this.ordering);
        }
        this.toggleSelecting();
      }
      return;
    }
  }
};
</script>

<style scoped lang="scss">
.product-related {
  @include grid($cols: 1fr, $rows: 40px auto auto, $row-gap: 8px);
}
.product-related .related-product .display {
  @include grid($cols: 100px 1fr, $col-gap: 10px);
}
/* .line-clamp {
  height: 50px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
} */
.display--overflow {
  max-height: 300px;
}
.display--list {
  @include grid($cols: 1fr, $row-gap: 10px);
}
.related-display {
  @include grid($cols: 50px 1fr, $col-gap: 10px, $align: center);
  padding: 15px;
  background-color: lighten($frost, 10%);
  border-radius: 5px;
  margin-left: 20px;
}
.reorder {
  grid-template-columns: 25px 75px 1fr;
  margin-bottom: 10px;
}
.search--bar {
  max-width: 500px;
  margin: auto;
}
.products--overflow {
  height: 200px;
  padding: 10px;
  padding-left: 20px;
}
.products--list {
  @include grid($cols: 1fr, $row-gap: 8px, $col-gap: 15px);
  li label {
    @include grid($cols: 1fr 6fr, $col-gap: 5px, $align: center);
  }
}
@media (min-width: $md) {
  .products--list {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $lg) {
  .products--list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>