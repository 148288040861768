<template>
  <div class="result-product--status text__right" :class="bgClass">
    <font-awesome-icon :icon="icon" size="1x" class="mr-10 text__white" />
    <span class="text__white">{{ cleanStatus }}</span>
  </div>
</template>

<script>
export default {
  props: ["status"],
  computed: {
    cleanStatus() {
      if (this.status === "coming-soon") {
        return "Coming Soon!";
      }
      return "Working on It";
    },
    icon() {
      if (this.status === "coming-soon") {
        return "clock";
      }
      return "tools";
    },
    bgClass() {
      if (this.status === "coming-soon") {
        return "bg-green";
      }
      return null;
    }
  }
};
</script>

<style scoped lang="scss">
.result-product--status {
  @include pos-abs(
    $pos: absolute,
    $top: null,
    $left: null,
    $bottom: -5px,
    $right: -5px,
    $width: 80%
  );
  padding: 2px 0;
  padding-right: 25px;
  margin: 0 !important;
  clip-path: polygon(11% 0, 100% 0, 100% 100%, 0% 100%);
  background: linear-gradient(135deg, $maroon, $black);
}
.bg-green {
  background: linear-gradient(135deg, $green, $black);
}
</style> 