<template>
  <section class="margin__md">
    <h2>Image Gallery</h2>
    <p class="text__center text__bold mar__down">{{ product.name }}</p>
    <VueSlickCarousel v-bind="settings">
      <img
        v-for="img in product.images"
        :key="img"
        :src="img"
        :alt="`product image for ${product.name}`"
        width="100%"
        height="100%"
      />
    </VueSlickCarousel>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
export default {
  props: ["product"],
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      settings: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        touchThreshold: 5,
        initialSlide: 0,
        focusOnSelect: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  }
};
</script>

<style scoped lang="scss">
.mar__down {
  margin-bottom: -35px;
  margin-top: 15px;
}
</style>