<template>
  <section class="product-notes tile-mini">
    <div class="flex__between">
      <h2>Notes</h2>
      <button class="btn btn__sm btn__red" @click="toggleEditing">{{ editing ? "Cancel" : "Edit" }}</button>
    </div>
    <div class="product-notes--content p-tb-sm over__hidden">
      <div class="flex__between mb-15">
        <p class="text__bold mr-10">Link</p>
        <p v-if="!editing">
          <a
            v-if="product.productLink"
            :href="product.productLink"
            target="_blank"
            rel="nofollow"
            name="view product listing"
            class="link__none"
          >view listing</a>
          <span v-else>no link</span>
        </p>
        <input v-else type="text" v-model="form.productLink" />
      </div>
      <div
        v-if="!editing && !product.productNotes"
        class="flex__center product-notes--content__default"
        @click="toggleEditing"
      >
        <p
          class="text__bold text__center"
        >No notes available for this product. Click to create some.</p>
      </div>
      <div
        v-if="!editing && product.productNotes"
        class="product-notes--display"
        v-html="product.productNotes"
      ></div>
      <Editor v-if="editing" :api-key="apiKey" :init="config" v-model="form.productNotes" />
    </div>
    <div v-if="editing" class="flex__between">
      <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
      <button class="btn btn__sm btn__green" @click="submit">Done</button>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { createError } from "../../../libs/flash-messages";
import { tinyConfig } from "../../../plugins/tinymce";
export default {
  props: ["canEdit", "product"],
  data() {
    return {
      editing: false,
      form: {
        _id: this.product ? this.product._id : null,
        productLink: this.product ? this.product.productLink : null,
        productNotes: this.product ? this.product.productNotes : ""
      }
    };
  },
  computed: {
    apiKey() {
      return tinyConfig.apiKey;
    },
    config() {
      return tinyConfig.config;
    }
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateProduct"]),
    async submit() {
      if (!this.canEdit)
        return createError(
          "Access denied",
          "You are not permitted to perform this action"
        );
      this.startSetIsSubmitting({ bool: true, heading: "Updating" });
      try {
        await this.startUpdateProduct(this.form);
        this.startSetIsSubmitting({ bool: false });
        this.toggleEditing();
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    toggleEditing() {
      this.editing = !this.editing;
    }
  }
};
</script>

<style scoped lang="scss">
.product-notes--content__default {
  background-color: $frost;
  height: 200px;
}
.product-notes--display >>> ul {
  list-style: disc;
  padding-left: 25px;
}
</style>