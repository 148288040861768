<template>
  <section class="product-header">
    <div class="container">
      <div v-if="backConfirm" class="flex__right">
        <router-link :to="backConfirm" class="breadcrumb">
          Back to Confirm
          <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
        </router-link>
      </div>
      <div v-else class="flex__between sticky">
        <span v-if="isAdmin" @click="goBackAdminProducts" class="breadcrumb">
          <font-awesome-icon icon="arrow-left" size="sm" class="mr-10" />Back to Products
        </span>
        <span v-if="!isAdmin" @click="goBackProducts" class="breadcrumb">
          <font-awesome-icon icon="arrow-left" size="sm" class="mr-10" />Back to Products
        </span>
        <router-link v-if="backConfirm" :to="newPastConfirmLink" class="breadcrumb">
          Back to Confirm
          <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
        </router-link>
        <button v-if="isAdmin" class="btn btn__sm btn__red mt-15" @click="emitToggleDeleting">Delete</button>
        <router-link
          v-if="showNew"
          to="/new-order/order-details"
          class="btn btn__sm btn__green mt-15"
        >New Order</router-link>
      </div>
      <!-- <p
        v-if="getNotes && getNotes.productNotes"
        class="header--message pre-wrap"
      >{{ getNotes.productNotes }}</p>-->
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["canEdit", "product", "order"],
  computed: {
    ...mapGetters(["getUserDetails"]),
    route() {
      return this.$route.fullPath;
    },
    link() {
      if (this.route.includes(`/new-order/${this.order._id}/products`)) {
        return `/new-order/${this.order._id}/products`;
      } else if (this.route.includes("/past-order")) {
        return `/past-order/${this.order._id}/products`;
      } else if (this.route.includes("/admin")) {
        return "/admin/products";
      } else {
        return "/products";
      }
    },
    isAdmin() {
      return this.route.includes("/admin");
    },
    showNew() {
      return (
        !this.route.includes("/admin") &&
        !this.route.includes("/new-order") &&
        !this.route.includes("/past-order")
      );
    },
    backConfirm() {
      if (this.$route.query.item) {
        return this.route.replace(/\/products\/.*/, "/order-confirm");
      }
      return null;
    },
    showBackWithProducts() {
      return (
        !this.$route.query.item &&
        (this.route.includes("/new-order") ||
          this.route.includes("/past-order"))
      );
    },
    newPastConfirmLink() {
      return this.route.replace(/\/products\/.*/, "/order-confirm");
    }
    // backProducts() {
    //   const route = this.$route.fullPath;
    //   return route.replace(/\/[a-z0-9?=]+$/gim, "");
    // }
  },
  methods: {
    emitToggleDeleting() {
      if (this.canEdit) {
        this.$emit("toggleDeleting", true);
      }
      return;
    },
    goBackAdminProducts() {
      //this.$router.go(-1);
      this.$router.replace('/admin/products');
    },
    goBackProducts() {
      //this.$router.go(-1);
      this.$router.replace('/products');
    }
    
  }
};
</script>

<style scoped lang="scss">
.product-header {
  position: sticky;
  top: calc(#{$header-height-sm} - #{$sticky-height});
  background-color: lighten($frost, 20%);
  z-index: 5;
  padding-bottom: 5px;
}
</style>