<template>
  <section class="product-attr tile-mini">
    <div class="flex__between">
      <h2>Attributes</h2>
      <button class="btn btn__sm btn__red" @click="toggleEditing">{{ editing ? "Cancel" : "Edit" }}</button>
    </div>
    <div class="product-attr__grid p-tb-sm">
      <label for="#">
        Categories
        <span v-if="!editing" class="text__light">{{ categoryList }}</span>
        <simplebar v-else class="category--overflow" data-simplebar-auto-hide="false">
          <div class="category--select__grid">
            <label v-for="category in categories" :key="category._id">
              <input type="checkbox" v-model="form.categories" :value="category._id" />
              {{ category.name }}
            </label>
          </div>
        </simplebar>
      </label>
      <label>
        Tags
        <span v-if="!editing" class="text__light">{{ tagList }}</span>
        <input v-else type="text" v-model="tags" placeholder="comma separated" />
      </label>
    </div>
    <div v-if="editing" class="flex__between">
      <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
      <button class="btn btn__sm btn__green" @click="submit">Done</button>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { createError } from "../../../libs/flash-messages";
export default {
  props: ["categories", "canEdit", "product"],
  data() {
    return {
      editing: false,
      form: {
        _id: this.product ? this.product._id : null,
        categories: this.product ? this.product.categories : [],
        tags: []
      },
      tags: this.product ? this.product.tags.join(", ") : ""
    };
  },
  computed: {
    categoryList() {
      if (this.product.categories.length === 0) {
        return "No categories selected for this product";
      }
      return this.product.categories
        .map(id => {
          return this.categories.filter(category => {
            return category._id === id;
          })[0].name;
        })
        .join(", ");
    },
    tagList() {
      if (this.product.tags.length <= 0) {
        return "No tags entered for this product";
      }
      return this.product.tags.join(", ");
    }
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateProduct"]),
    async submit() {
      if (!this.canEdit)
        return createError(
          "Access denied",
          "You do not have permission to perform this action"
        );
      this.startSetIsSubmitting({ bool: true, heading: "Updating" });
      this.form.tags = this.tags
        ? this.tags
            .toLowerCase()
            .split(",")
            .map(item => item.trim())
        : [];
      try {
        await this.startUpdateProduct(this.form);
        this.startSetIsSubmitting({ bool: false });
        this.toggleEditing();
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    toggleEditing() {
      if (this.canEdit) {
        this.editing = !this.editing;
      }
      return;
    }
  }
};
</script>

<style scoped lang="scss">
.tile-mini {
  padding: 15px 20px;
}
.product-attr__grid {
  @include grid($cols: 1fr, $col-gap: 10px, $row-gap: 15px);
  label {
    font-weight: bold;
    @include grid($cols: 1fr, $col-gap: 5px, $row-gap: 3px);
  }
}
.category--overflow {
  max-height: 200px;
}
.category--select__grid {
  @include grid($cols: 1fr, $row-gap: 5px, $col-gap: 10px, $align: center);
  padding: 10px;
  padding-left: 20px;
  label {
    font-weight: normal;
    @include grid($cols: 1fr 3fr, $col-gap: 10px, $align: center);
  }
}
@media (min-width: $md) {
  .product-attr {
    margin-bottom: 0;
  }
}
@media (min-width: $lg) {
  .category--select__grid {
    grid-template-columns: 1fr 1fr;
  }
}
</style>