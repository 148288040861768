<template>
  <div class="result-product--featured text__center">
    <span class="text__white text__bold">Featured</span>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.result-product--featured {
  @include pos-abs($pos: absolute, $top: -5px, $left: -5px, $width: 175px);
  padding: 5px 10px;
  background-color: $peacock;
  margin: 0 !important;
  clip-path: polygon(0% 0%, 100% 1%, 83% 100%, 0% 100%);
}
span {
  margin-right: 25px;
}
</style> 