<template>
  <section class="product-pair tile-mini">
    <div class="flex__between">
      <h2>Paired</h2>
      <button class="btn btn__sm btn__red" @click="toggleEditing">{{ editing ? "Cancel" : "Edit" }}</button>
    </div>
    <p>Selected product will be displayed as a pairing and suggested when they add to order</p>
    <div v-if="!editing" class="paired-product">
      <div v-if="!paired" class="default padding__sm">
        <p class="text__bold text__center">No paired product selected</p>
      </div>
      <div v-else class="display">
        <img
          :src="paired.images[0]"
          :alt="`Product image for ${paired.name}`"
          width="100%"
          height="100%"
        />
        <!-- <div> -->
        <p class="text__bold">{{ paired.name }}</p>
        <!-- <p class="line-clamp">{{ paired.description }}</p> -->
        <!-- </div> -->
      </div>
    </div>
    <div v-else class="paired-product--search">
      <div class="search--bar">
        <input type="text" v-model="search" placeholder="search" />
        <div class="btn__search">
          <font-awesome-icon icon="search" size="lg" class="icon" />
        </div>
      </div>
      <simplebar class="products--overflow" data-simplebar-auto-hide="false">
        <ul class="products--list">
          <li>
            <label>
              <input type="radio" :value="null" v-model="form.paired" />
              Remove paired product
            </label>
          </li>
          <li v-for="product in filteredProducts" :key="product._id">
            <label>
              <input type="radio" :value="product._id" v-model="form.paired" />
              {{ product.name.length > 15 ? `${product.name.slice(0,15)}...` : product.name }}
            </label>
          </li>
        </ul>
      </simplebar>
    </div>
    <div v-if="editing" class="flex__between">
      <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
      <button class="btn btn__sm btn__green" @click="submit">Done</button>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { createError } from "../../../libs/flash-messages";
export default {
  props: ["canEdit", "product"],
  data() {
    return {
      editing: false,
      search: "",
      form: {
        _id: this.product ? this.product._id : null,
        paired: this.product ? this.product.paired : null
      }
    };
  },
  computed: {
    ...mapGetters(["getLiveProducts"]),
    paired() {
      if (!this.product.paired) return null;
      const found = this.getLiveProducts.filter(
        item => item._id === this.product.paired
      );
      if (found.length > 0) return found[0];
      return null;
    },
    filteredProducts() {
      if (!this.search) return this.getLiveProducts;
      return this.getLiveProducts.filter(product =>
        product.name.toLowerCase().includes(this.search.toLowerCase())
      );
    }
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateProduct"]),
    async submit() {
      if (!this.canEdit)
        return createError(
          "Access denied",
          "You do not have permission to perform this action"
        );
      this.startSetIsSubmitting({ bool: true, heading: "Updating" });
      try {
        await this.startUpdateProduct(this.form);
        this.startSetIsSubmitting({ bool: false });
        this.toggleEditing();
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    toggleEditing() {
      if (this.canEdit) {
        this.editing = !this.editing;
      }
      return;
    }
  }
};
</script>

<style scoped lang="scss">
.product-pair {
  @include grid($cols: 1fr, $rows: 40px auto auto, $row-gap: 8px);
}
.product-pair .paired-product .display {
  @include grid($cols: 100px 1fr, $col-gap: 10px);
}
/* .line-clamp {
  height: 50px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
} */
.products--overflow {
  height: 150px;
  padding: 10px;
  padding-left: 20px;
}
.products--list {
  @include grid($cols: 1fr, $row-gap: 8px);
  li label {
    @include grid($cols: 1fr 6fr, $col-gap: 5px, $align: center);
  }
}
</style>