<template>
  <router-link :to="link" class="link__none">
    <li class="results-product tile">
      <Featured v-if="product.isFeatured" />
      <!-- <Respa v-if="product.respaStatus !== 'employee only'" :status="product.respaStatus" /> -->
      <div class="resuts-product--img__div flex__center">
        <div
          v-if="product.images.length <= 0"
          class="results-product--img__default flex__center text__center text__sm"
        >No product image present</div>
        <img
          v-else
          class="results-product--img"
          :src="product.images[0]"
          :alt="`Product image for ${product.name}`"
        />
      </div>
      <p class="text__bold text__md text__wrap pre-wrap">{{ product.name }}</p>
      <p class="flex__left">
        <span class="mr-10 text__bold">Cost</span>
        <span class="text__md">${{ product.loCost.toFixed(2) }}</span>
      </p>
      <p class="flex__col">
        <span class="text__bold">Description:</span>
        <span>{{ shortDescription }}</span>
      </p>
      <p>
        <span class="text__bold mr-10">Categories:</span>
        <span v-if="product.categories.length <= 0">none</span>
        <span
          v-else
          v-for="(category, index) in categoryList"
          :key="category._id"
          @click.prevent="categoryPush(category._id)"
          class="text__peacock"
        >{{ category.name }}{{ index !== categoryList.length - 1 ? ", " : null }}</span>
      </p>
      <p class="flex__right text__bold">
        <router-link :to="link" class="link__none">
          View
          <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
        </router-link>
      </p>
      <Status v-if="product.status !== 'live'" :status="product.status" />
    </li>
  </router-link>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Featured from "./Featured";
//import Respa from "./Respa";
import Status from "./Status";
export default {
  props: ["product"],
  components: {
    Featured,
    //Respa,
    Status
  },
  computed: {
    ...mapGetters(["getCategories", "getActiveOrder"]),
    route() {
      return this.$route.fullPath;
    },
    baseLink() {
      return this.route.replace(/\?[a-z0-9=&]+/g, "");
    },
    link() {
      if (this.route.includes("/new-order")) {
        return `/new-order/${this.getActiveOrder._id}/products/${this.product._id}`;
      } else if (this.route.includes("/past-order")) {
        return `/past-order/${this.getActiveOrder._id}/products/${this.product._id}`;
      } else if (this.route.includes("/admin")) {
        return `/admin/products/${this.product._id}`;
      } else {
        return `/products/${this.product._id}`;
      }
    },
    shortDescription() {
      return `${this.product.description.slice(0, 100)}${
        this.product.description.length > 100 ? "..." : ""
      }`;
    },
    categoryList() {
      return this.product.categories.map(id => {
        return this.getCategories.filter(category => {
          return category._id === id;
        })[0];
      });
    }
  },
  methods: {
    ...mapActions(["startUnsetProductFilters", "startSetProductFilters"]),
    toggleDescription() {
      this.descriptionToggled = !this.descriptionToggled;
    },
    categoryPush(category) {
      const filters = {
        name: "",
        priceMin: null,
        priceMax: null,
        respaStatus: [],
        categories: [category],
        status: [],
        showFeatured: true,
        priceSort: "high-to-low"
      };
      this.startUnsetProductFilters();
      this.startSetProductFilters(filters);
      this.$emit("resetPage");
    }
  }
};
</script>

<style scoped lang="scss">
.results-product {
  background-color: lighten($frost, 5%);
  /* background-color: $pistachio; */
  div,
  p {
    margin: 5px 0;
  }
  position: relative;
  height: 100%;
  transition: all 0.25s ease-in-out;
}
.results-product:hover {
  @include shadow;
  background-color: lighten($frost, 10%);
}
.resuts-product--img__div {
  padding: 18px 0;
}
.results-product--img,
.results-product--img__default {
  width: 200px;
  height: 200px;
}
.results-product--img__default {
  background-color: $frost;
  border: 1px dashed $dark-blue;
  border-radius: 5px;
}
</style>